<template>
  <v-row class="bbr-list--completed">
    <v-col cols="12">
      <div class="form-container">
        <div class="search-form">
          <v-row no-gutter>
            <v-col cols="4">
              <v-text-field
                flat
                hide-details
                solo
                rounded
                clearable
                label="Search food entries"
                v-model="searchKey"
                @input="searchChange"
                autocomplete="off"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="filter.category"
                :items="categories"
                item-text="name"
                item-value="id"
                label="Category"
                @change="categoryChange"
                persistent-hint
                hide-details
                single-line
                clearable
                rounded
                solo
                flat
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <recipe-list-table
        v-if="type == 1"
        :is-searching="isSearching"
        :list="list"
        :loading="loading"
        :meta="listMeta"
        list-for="Active"
        @loadMore="loadMoreContent"
        @goTo="goTo"
      />
    </v-col>
  </v-row>
</template>

<script>
import RecipeListTable from '../components/RecipeListTable'
import { mapMutations, mapState, mapActions } from 'vuex'
import Event from '@/services/eventBus'
import { debounce, extend } from 'lodash'

export default {
  name: 'ActiveReceiptPage',

  components: {
    RecipeListTable,
  },

  data() {
    return {
      loading: false,
      searchKey: null,
      categories: [],
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.recipe.filter,
      list: (state) => state.recipe.completed.list,
      listMeta: (state) => state.recipe.completed.listMeta,
    }),

    hasListData() {
      return !!this.list.length
    },

    isSearching() {
      return !!this.searchKey
    },

    type() {
      return this.$route.meta ? this.$route.meta.type : 2
    },
  },

  created() {
    this.setFilter({ completed: true })
    this.clearList()

    if (this.filter.completed) {
      this.searchKey = this.filter.search
    }

    this.loadRecipeCategories()

    if (this.searchKey) {
      this.fetchListData()
    }

    Event.$on('update-active-recipe-list', () => {
      this.setFilter({ completed: true })
      this.clearList()
      this.fetchListData()
    })
  },

  methods: {
    ...mapActions({
      recipeCategories: 'recipe/getRecipeCategories',
      getDataList: 'recipe/getDataList',
    }),

    ...mapMutations({
      setFilter: 'recipe/setFilter',
      clearList: 'recipe/clearList',
    }),

    async loadRecipeCategories() {
      this.categories = await this.recipeCategories()
    },

    searchChange(key) {
      this.setFilter({
        search: key,
        completed: true,
      })

      this.searchData()
    },

    categoryChange(category) {
      this.setFilter({
        category: category,
        completed: true,
      })

      this.searchData()
    },

    searchData: debounce(function() {
      this.clearList()
      this.fetchListData()
    }, 550),

    async fetchListData(page = 1) {
      if (this.loading) return

      let search = this.searchKey
      let completed = 1
      // type: 1 Recipe, 2 Food
      let params = {
        page,
        type: this.type,
        search,
        ...this.filter,
        status: this.$route.meta.status,
      }

      if (this.type === 1) {
        params = extend(params, { completed })
      }

      this.loading = true

      await this.getDataList(params)

      this.loading = false
    },

    loadMoreContent() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchListData(this.listMeta.current_page + 1)
      }
    },

    goTo(id) {
      this.$router.push({ name: 'form.recipe', params: { id } })
    },
  },
}
</script>
